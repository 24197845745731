.tnc-text ul {
    padding-left: 16px;
}

.tnc-text hr {
    border: 1px solid #dfe4e8;
}

.tnc-text h1 {
    font-size: 16px;
}

.tnc-text h2 {
    font-size: 14px;
}

.tnc-text a {
    font-weight: bold;
    font-size: 13px;
    color: #5352ff;
    text-decoration: none;
}

.tnc-text .boxed {
    background-color: #F7F9FA;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: -16px;
    margin-top: 16px;
    border-left: 4px solid #DFE4E8;
}

ul ul {
    list-style-type: disc;
}

.tnc-text {
    padding-left: 24px;
    padding-right: 24px;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}
